@import 'src/style/use';

.leaderboard-row {
	@padding-v: .125rem;
	@height: 2.5rem;

	position: relative;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: stretch;
	padding: @padding-v 0;
	height: @height + (@padding-v * 2);

	.at-mobile({
		height: (@height * 2) + (@padding-v * 2) + 1rem;
	});

	.colorize(@color) {
		border-color: @color;
		background-color: @color;
	}

	&__m-loading {
		@keyframes loading {
			0% {
				transform: translateX(-100%);
				opacity: .5;
			}

			100% {
				transform: translateX(0);
				opacity: 0;
			}
		}

		opacity: .5;

		&:before,
		&:after {
			content: '';
		}

		&:before {
			position: absolute;
			top: .5rem;
			left: 0;
			right: 0;
			bottom: .5rem;
			background-color: var(--page-color-text);
			opacity: .25;
		}

		&:after {
			position: absolute;
			top: .5rem;
			left: 0;
			right: 0;
			bottom: .5rem;
			background-color: var(--page-color-text);
			opacity: .5;
			transform: translateX(-100%);
			animation: loading 1s linear infinite;
			animation-delay: var(--element-ld-animation-delay, 0);
		}
	}

	&__data {
		position: relative;
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		align-items: stretch;
		gap: 1rem;
		width: 100%;
		border: 1px solid transparent;
		background-color: var(--page-color-lightest);

		.at-mobile({
			display: grid;
			gap: .5rem;
			grid-template-columns: repeat(3, 1fr);
			grid-template-areas: "position username score" "gift gift gift";
		});

		&:hover {
			&:before {
				opacity: .1;
			}
		}

		&:before {
			content: '';
			position: absolute;
			inset: 0;
			background-color: var(--page-color-text);
			opacity: 0;
			z-index: 0;
			transition: opacity .2s linear;
		}

		&__m-selected {
			.colorize(var(--page-color-accent));
		}

		&__position {
			position: relative;
			grid-area: position;
			padding-inline: .5rem;
			line-height: @height;
			min-width: 6rem;
			text-align: center;
			z-index: 1;

			.at-mobile({
				min-width: auto;
				text-align: left;
			});
		}

		&__username {
			position: relative;
			grid-area: username;

			flex: 1;
			font-size: 1.1rem;
			line-height: @height;
			padding-inline-start: 1rem;
			white-space: nowrap;
			z-index: 1;

			.at-mobile({
				text-align: center;
				padding-inline-end: .5rem;
			});
		}

		&__gift {
			position: relative;
			grid-area: gift;
			font-size: 1.1rem;
			line-height: @height;
			white-space: nowrap;
			opacity: .75;
			z-index: 1;

			.at-mobile({
				overflow: hidden;
				text-align: center;
				text-overflow: ellipsis;
				padding-inline: .5rem;
			})
		}

		&__score {
			position: relative;
			grid-area: score;
			padding-inline-end: 1rem;
			line-height: @height;
			font-weight: 600;
			text-align: right;
			min-width: 6rem;
			z-index: 1;

			.at-mobile({
				padding-inline-end: .5rem;
			});
		}
	}
}
