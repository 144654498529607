@import 'src/style/use';

.leaderboard-selector {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: stretch;
	gap: 3rem;
	margin-block-start: 2rem;

	.at-mobile({
		gap: 1rem;
		margin-block-start: 0;
		margin-block-end: 0;
	});

	&__tabs {
		&__item {
			width: auto;
			height: 100%;
			padding: .5rem 1rem;
			display: flex;
			flex-direction: row;
			justify-content: flex-start;
			align-items: center;
			gap: 1rem;

			&__logo {
				.size(2rem);
				background-size: contain;
				background-position: center;
				background-repeat: no-repeat;
			}

			&__data {
				flex: 1;
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: flex-start;
				gap: .25rem;
				color: var(--page-color-text);

				&__subtitle {
					opacity: .75;
					font-size: .75rem;
					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;
				}

				&__title {
					font-size: 1rem;
					font-weight: 600;
					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;
				}
			}
		}
	}

	&__title {

	}
}
