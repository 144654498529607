@import 'src/style/use';

.button-social-auth {
	position: relative;

  	& > iframe {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      opacity: .01 !important;
      z-index: 10;
    }
}
