@import "src/style/use";

.leaderboard-pagination {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: stretch;

	&__button,
	&__ellipsis  {
		display: inline-block;
		background: none;
		border: none;
		color: var(--page-color-text);
		padding: .25rem 1rem;
		opacity: .5;
		transition: opacity .2s linear;
		min-width: 3.75rem;
		text-align: center;

		.at-mobile({
			min-width: 2rem;
			padding: .25rem .5rem;
		});
	}

	&__button {
		cursor: pointer;

		&__m-current {
			opacity: 1;
		}
	}
}
