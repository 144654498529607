@import 'src/style/use';

.throbber {
	position: relative;

	&__m-size-default {
		.size(5rem);

		&:before {
			inset: 1rem;
		}

		&:after {
			inset: 2rem;
		}
	}

	&__m-size-small {
		.size(2rem);

		&:before {
			inset: 0;
		}

		&:after {
			inset: .5rem;
		}
	}

	@keyframes rotate-cw {
		0% {
			transform: rotate(0deg) scale(1);
		}

		40% {
			transform: rotate(180deg) scale(.75);
		}

		80%, 100% {
			transform: rotate(360deg) scale(1);
		}
	}

	@keyframes rotate-ccw {
		0% {
			transform: rotate(0deg) scale(1);
		}

		40% {
			transform: rotate(-180deg) scale(1.25);
		}

		80%, 100% {
			transform: rotate(-360deg) scale(1);
		}
	}

	&:before, &:after {
		content: '';
		position: absolute;
	}

	&:before {
		border: 2px solid var(--page-color-accent);
		animation: rotate-cw 1.6s cubic-bezier(0.25, 1, 0.5, 1) infinite;
	}

	&:after {
		border: 2px solid var(--page-color-text);
		animation: rotate-ccw 1.5s cubic-bezier(0.33, 1, 0.68, 1) infinite;
	}
}
