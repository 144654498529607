@import 'src/style/use';

.gifts-carousel {
	--gifts-carousel-control-color: var(--page-color-accent);
	--gifts-carousel-item-background-color: var(--page-color-dark);

	@height : 17rem;

	position: relative;
	width: 100%;
	height: @height;

	&__m-loading {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}

	&__content {
		.size(100%);
		position: relative;
		overflow: hidden;

		&__slider {
			position: absolute;
			top: 0;
			left: 0;
			bottom: 0;
			width: 100%;
			height: 100%;
			display: flex;
			flex-wrap: nowrap;
			flex-direction: row;
			justify-content: flex-start;
			align-items: stretch;
			gap: 1rem;

			&__m-transition {
				transition: transform .2s cubic-bezier(0.22, 1, 0.36, 1);
			}

			&__item {
				.size(12rem, @height);
				.with-gradient-border-top();
				background: var(--gifts-carousel-item-background-color);
				position: relative;
				flex-grow: 1;
				flex-shrink: 0;

				&__position {
					position: absolute;
					top: .75rem;
					left: .75rem;
					padding: .25rem;
					white-space: nowrap;
					color: var(--page-color-text);
					background-color: var(--page-color-accent);
					user-select: none;
					font-weight: 600;
				}

				&__gift {
					position: relative;
					.size(100%);
				}
			}
		}
	}

	&__control {
		position: absolute;
		top: 0;
		bottom: 0;
		width: 3rem;
		background: none;
		border: none;
		color: var(--gifts-carousel-control-color);
		text-align: center;
		cursor: pointer;
		padding: 0;
		transform: scale(1);
		transition: transform .2s cubic-bezier(0.22, 1, 0.36, 1);
		display: none;

		.at-desktop(1280px, {
			display: block;
		});

		&:hover {
			transform: scale(1.1);

			&::before {
				box-shadow: inset .25rem .25rem 0 currentColor, inset -.25rem -.25rem currentColor;
			}
		}

		&:before {
			content: '';
			display: inline-block;
			.size(1.5rem);
			box-shadow: inset 0 0 0 currentColor, inset 0 0 currentColor;
			border: 2px solid currentColor;
			transform: rotate(45deg);
			transition: box-shadow .2s linear;
		}

		&__m-left  {
			left: -3rem;
			transform-origin: right center;

			&:before {
				clip-path: polygon(0% 0%, 100% 100%, 0% 100%);
				margin-left: 1rem;
			}
		}

		&__m-right {
			right: -3rem;
			transform-origin: left center;

			&:before {
				clip-path: polygon(0% 0%, 100% 100%, 100% 0%);
				margin-left: -1rem;
			}
		}
	}
}
