@import 'src/style/use';

.button {
	position: relative;
	display: block;
	padding: .5rem 1rem;
	color: var(--page-color-text);
	background-color: var(--page-color-accent);
	text-transform: uppercase;
	white-space: nowrap;
	border: none;
	cursor: pointer;

	&, &:visited {
		color: var(--page-color-text);
		text-decoration: none;
	}

	&&__m-arrow-on-hover-right,
	&&__m-arrow-on-hover-bottom {
		&:before,
		&:after {
			content: '';
			position: absolute;
			display: block;

			opacity: 0;
			transform: translateX(100%);
			aspect-ratio: 1 / 1;
			background-color: var(--page-color-accent);
		}
	}

	&&__m-arrow-on-hover-right {
		@keyframes arrow-move-right {
			0% {
				opacity: 0;
				transform: translateX(50%);
			}

			50% {
				opacity: 1;
			}

			100% {
				opacity: 0;
				transform: translateX(150%);
			}
		}

		&:before,
		&:after {
			top: .25rem;
			bottom: .25rem;
			right: 0;
			clip-path: polygon(0% 0%, 50% 50%, 0% 100%);
		}

		&:hover {
			&:before {
				animation: arrow-move-right .6s linear infinite;
				animation-delay: 0;
			}

			&:after {
				animation: arrow-move-right .8s linear infinite;
				animation-delay: .2s;
			}
		}
	}

	&&__m-arrow-on-hover-bottom {
		@keyframes arrow-move-bottom {
			0% {
				opacity: 0;
				transform: translateY(50%);
			}

			50% {
				opacity: 1;
			}

			100% {
				opacity: 0;
				transform: translateY(150%);
			}
		}

		&:before,
		&:after {
			left: 50%;
			margin-left: -1.5rem;
			bottom: 0;
			clip-path: polygon(0% 0%, 50% 100%, 100% 100%);
		}

		&:hover {
			&:before {
				animation: arrow-move-bottom .6s linear infinite;
				animation-delay: 0;
			}

			&:after {
				animation: arrow-move-bottom .8s linear infinite;
				animation-delay: .2s;
			}
		}
	}

}
