.marquee {
	position: relative;

	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	align-items: center;
	justify-content: flex-start;
	width: 100%;
	overflow: hidden;

	&__content {
		position: relative;

		float: left;

		white-space: nowrap;

		transition-timing-function: linear;
		transition-property: transform;

		will-change: transform;
	}
}
