@import 'src/style/use';

.video-player {
	width: 100%;
	position: relative;

	&&__m-playing {
		.video-player {
			&__overlay {
				&__control {
					top: calc(100% - 4rem);
					left: 4rem;

					&__icon {
						&__m-play {
							transform: scale(2);
							opacity: 0;
						}

						&__m-pause {
							transform: scale(1);
							opacity: 1;
						}
					}
				}

				&__background {
					opacity: .1;
				}
			}
		}
	}

	&__overlay {
		position: absolute;
		inset: 0;
		bottom: .5rem;
		.size(100%, calc(100% - .25rem));
		z-index: 1;

		&__control {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%) scale(1);
			.size(4rem);
			background-color: var(--page-color-base);
			border: none;
			border-radius: .25rem;
			cursor: pointer;
			z-index: 2;
			transition:
				transform .2s cubic-bezier(0.16, 1, 0.3, 1),
				top .2s cubic-bezier(0, 0.55, 0.45, 1),
				left .2s cubic-bezier(0.33, 1, 0.68, 1);

			&:hover {
				transform: translate(-50%, -50%) scale(1.2);
			}

			&__icon {
				position: absolute;
				inset: .5rem;
				color: var(--page-color-accent);
				transform: scale(0);
				opacity: 0;
				transition: transform .2s cubic-bezier(0.16, 1, 0.3, 1), opacity .2s linear;

				&__m-play {
					transform: scale(1);
					opacity: 1;
				}

				&__m-pause {
					transform: scale(0);
					opacity: 0;
				}

				& > svg {
					.size(3.5rem);
					margin-block-start: -.25rem;
					margin-inline-start: -.25rem;
				}
			}
		}

		&__background {
			position: absolute;
			inset: 0;
			.size(100%);
			background-image: radial-gradient(circle at 50% 50%, var(--page-color-accent) 0%, var(--page-color-base) 100%);
			opacity: .85;
			transition: opacity .2s linear;
			mix-blend-mode: overlay;
		}
	}

	&__player {
		position: relative;
		width: 100%;
		aspect-ratio: 16 / 9;
		z-index: 0;
	}
}
