.with-gradient-border(@border, @color) {
	border-@{border}-width: 1px;
	border-@{border}-style: solid;
	border-image: linear-gradient(90deg, transparent 0%, @color 50%, transparent 100%) 1 0%;
}

.with-gradient-border-top(@color: var(--page-color-border)) {
	.with-gradient-border(top, @color);
}

.with-gradient-border-bottom(@color: var(--page-color-border)) {
	.with-gradient-border(bottom, @color);
}
