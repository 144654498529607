@import "src/style/use";

.tabs {
	--tabs-background-color-idle: var(--page-color-lightest);
	--tabs-background-color-selected: var(--page-color-accent);

	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: flex-start;
	align-items: stretch;
	gap: .5rem;

	.at-mobile({
		flex-direction: column;
		flex-wrap: nowrap;
	});

	&__item {
		flex-grow: 0;
		flex-shrink: 1;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		height: 3.5rem;
		min-width: 15rem;
		text-decoration: none;
		background-color: var(--tabs-background-color-idle);
		border: 1px solid transparent;
		border-image: linear-gradient(180deg, #111213 0%, #2C2C2C 100%) 1;
		transition: background-color .2s linear;

		&__m-custom {
			display: block;
			flex-direction: unset;
			justify-content: unset;
			align-items: unset;
		}

		&__m-selected {
			background-color: var(--tabs-background-color-selected);
			border-color: var(--tabs-background-color-selected);
		}

		&__title {
			font-size: 1rem;
			line-height: 1rem;
			text-align: center;
			color: var(--page-color-text);
			font-weight: 600;
			text-transform: uppercase;
			white-space: nowrap;
		}
	}
}
