@import "src/style/use";

.header {
	.size(100%, var(--page-header-heigth));
	.with-gradient-border-bottom();
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: flex-start;
	background: radial-gradient(
		50% 50% at 50% 100%,
		var(--page-header-background-color-light) 0%,
		var(--page-header-background-color-dark) 100%
	);
	box-shadow: 0 1rem 1rem -.5rem var(--page-color-shadow);
	transition: height .1s linear;
	will-change: heght;

	&&__m-compact {
		height: calc(var(--page-header-heigth) - 2.5rem);
		transition: height .2s linear;
		opacity: .98;

		.header {
			&__content {
				transition: padding .2s linear;
				padding: .75rem 1rem;
			}
		}
	}

	&__content {
		position: relative;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		width: 100%;
		max-width: var(--page-max-width);
		height: 2rem;
		box-sizing: content-box;
		padding: 2rem 1rem;
		transition: padding .1s linear;
		will-change: padding;

		&__logo {
			position: absolute;
			top: 2rem;
			left: 1rem;
			.size(var(--page-logo-width), var(--page-logo-height));
			transform: translateY(var(--page-logo-vertical-compensation));
			background-image: var(--page-logo-image);
			background-size: contain;
			background-position: center;
			background-repeat: no-repeat;
		}
	}
}
