@import 'src/style/use';

.box-title {
	position: relative;
	padding: 3rem 25rem 3rem 2rem;
	color: var(--page-color-text);
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: stretch;
	gap: 2rem;

	.at-mobile({
		padding: 2rem 1rem;
		overflow: hidden;
	});

	&__data {
		position: relative;
		flex: 1;
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: flex-start;
		gap: .5rem;
		z-index: 1;

		&__title,
		&__subtitle,
		&__description {
			width: 100%;
			text-align: left;
		}

		&__title {
			font-size: 1.25rem;
			font-weight: 600;
			white-space: nowrap;
		}

		&__subtitle {
			font-weight: 600;
			white-space: nowrap;
		}

		&__description {
			font-size: 1rem;
			font-weight: 400;
			opacity: .75;
			white-space: pre-wrap;
		}
	}

	&__image {
		position: absolute;
		top: 0;
		bottom: 0;
		right: 0;
		height: 100%;
		aspect-ratio: 2 / 1;
		z-index: 0;

		.at-mobile({
			width: 100%;
			height: auto;
			top: auto;
			left: 0;
			right: 0;
			bottom: 0;
		});

		&__instance {
			.size(100%);

			&__m-desktop {
				.at-mobile({
					display: none;
				});
			}

			&__m-mobile {
				.at-desktop({
					display: none;
				})
			}
		}
	}
}
