.at-mobile(@breakpoint, @style) {
	@media screen and (max-width: @breakpoint) {
		@style();
	}
}

.at-mobile(@style) {
	.at-mobile(900px, @style);
}

.at-desktop(@breakpoint, @style) {
	@media screen and (min-width: @breakpoint) {
		@style();
	}
}

.at-desktop(@style) {
	.at-desktop(900px, @style);
}
