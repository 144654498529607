@import 'src/style/use';

.layout-main {
	.size(100%);
	min-height: 100dvh;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	.with-background();

	&:before {
		content: '';
		position: absolute;
		inset: 0;
		background-image: linear-gradient(180deg, var(--page-background-color-overlay) 0%, transparent 100%);
		z-index: 0;
	}

	&__header,
	&__content,
	&__footer {
		position: relative;
		z-index: 1;
	}

	&__header {
		.size(100%, auto);
	}

	&__content {
		flex: 1;
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: stretch;
		margin-block-end: 0;
		.size(100%, auto);
		max-width: var(--page-max-width);
	}
}
