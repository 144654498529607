.action-table-row {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	gap: 1rem;
	background-color: var(--page-color-lightest);
	padding: .5rem 2rem;
	min-height: 3.25rem;

	&__status {
	}

	&__content {
		flex: 1;
	}

	&__action {

	}
}
