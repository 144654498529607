@import 'src/style/use';

.leaderboard {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: stretch;
	gap: 1.25rem;
	.size(100%);

	&__header {
		width: 100%;
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		align-items: center;
		opacity: .5;

		.at-mobile({
			display: none;
		});

		&__position {
			padding-inline: .5rem;
			min-width: 6rem;
			text-align: center;
		}

		&__username {
			flex: 1;
			padding-inline-start: 2rem;
		}

		&__score {
			padding-inline-end: 1rem;
			min-width: 7rem;
			text-align: right;
		}
	}

	&__current-user {}

	&__list {
		flex: 1;

		& > * > * {
			// Override react-virtualized inline styles
			overflow-y: scroll !important;
		}
	}

	&__pagination {}
}
