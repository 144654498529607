.rules-list {
	&__list {
		&__item {
			display: flex;
			flex-direction: row;
			justify-content: flex-start;
			align-items: flex-start;
			gap: .5rem;
			margin-block-start: .5rem;

			&__number {
				display: inline-block;
				font-family: var(--page-font-family-mono);
				color: var(--page-color-base);
				background-color: var(--page-color-accent);
				padding: 0 .125rem 0 .75rem;
				margin-inline-end: .5rem;

				&:after {
					content: '. ',
				}
			}

			&__text {
				&:after {
					display: inline;
					content: ';';
				}
			}

			&:first-child {
				margin-block-start: 0;
			}

			&:last-child {
				.rules-list__list__item__text {
					&:after {
						display: inline;
						content: '.';
					}
				}
			}
		}
	}

	&__disclaimer {
		color: var(--page-color-accent);
		font-style: italic;
		font-size: .8rem;

		&:before {
			content: '* ';
		}
	}

	&__disclaimer-mark {
		color: var(--page-color-accent);

		&:before {
			content: '*';
		}
	}
}
