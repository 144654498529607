@import 'src/style/use';

.header-profile {
	display: inline-flex;
	flex-direction: row-reverse;
	justify-content: flex-start;
	align-items: center;
	gap: .5rem;
	text-decoration: none;

	&__avatar {
		display: inline-block;
		.size(2rem);
		background-size: cover;
		background-position: center;
		background-repeat: no-repeat;
		border-radius: 2rem;
	}

	&__label {
		color: var(--page-color-text);
		font-weight: 600;
	}
}
