.page-header {
	position: relative;
	font-family: var(--page-font-family-accent);
	font-size: 2.5rem;
	font-weight: 600;
	border-bottom: .125rem solid var(--page-color-accent);
	padding-block-end: .5rem;

	&:before {
		content: '';
		position: absolute;
		left: 0;
		right: 0;
		bottom: -.25rem;
		height: .125rem;
		background-color: var(--page-color-accent);
		opacity: .25;
	}
}
