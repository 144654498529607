@import "src/style/use";

.gift {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: stretch;
	position: relative;
	.size(12rem, auto);
	min-height: 16rem;
	color: var(--page-color-text);
	.with-gradient-border-top();

	&:hover {
		.gift {
			&__title {
				&__marquee {
					&__m-dismissible {
						opacity: 0;
						transform: translateY(-100%);
					}
				}

				& > :not(&__marquee) {
					opacity: 1;
					transform: translateY(0);
				}
			}

			&__hint {
				&__label {
					&__m-dismissible {
						opacity: 0;
						transform: scale(1.5);
					}
				}

				& > :not(&__label) {
					opacity: 1;
					transform: scale(1);
				}
			}
		}
	}

	&__amount {
		position: absolute;
		top: 1rem;
		left: 1rem;
		z-index: 1;
		background-color: var(--page-color-lightest);
	}

	&__media {
		position: relative;
		width: 100%;
		aspect-ratio: 1 / 1;

		&__item {
			position: absolute;
			inset: 1rem;
			background-position: center;
			background-size: contain;
			background-repeat: no-repeat;

			&__m-stub {
				opacity: .5;
			}
		}
	}

	&__title {
		display: flex;
		justify-content: center;
		align-items: center;
		text-align: center;
		font-weight: 600;
		opacity: .5;
		padding: 0 .5rem 1rem;
		position: relative;
		z-index: 0;

		&__marquee {
			width: auto;
			max-width: 100%;

			&__m-dismissible {
				transform: translateY(0);
				opacity: 1;
				transition: opacity .2s linear, transform .2s ease-out;
			}
		}

		& > :not(&__marquee) {
			position: absolute;
			inset: 0;
			z-index: 1;
			transform: translateY(100%);
			opacity: 0;
			transition: opacity .2s linear, transform .2s ease-in;
		}

		&__promocode {
			font-weight: 400;
		}
	}

	&__hint {
		position: relative;
		height: 2rem;
		display: flex;
		justify-content: center;
		align-items: center;
		text-align: center;
		background-color: var(--page-color-lightest);

		&__label {
			position: relative;
			display: flex;
			justify-content: center;
			align-items: center;
			text-align: center;
			line-height: 2rem;
			white-space: nowrap;
			text-transform: uppercase;
			z-index: 0;

			&__m-dismissible {
				transform: scale(1);
				opacity: 1;
				transition: opacity .2s linear, transform .2s ease-out;
			}

			&&__m-original {
				text-transform: none;
			}
		}

		& > :not(&__label) {
			position: absolute;
			inset: .5rem;
			line-height: 1rem;
			white-space: nowrap;
			text-transform: uppercase;
			opacity: 0;
			transform: scale(.5);
			transition: opacity .2s linear, transform .2s ease-in;
			z-index: 1;
		}

		&__promocode {
			text-transform: none;
		}
	}
}
