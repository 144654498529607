@import 'src/style/use';

.gifts-carousel-gifts {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: stretch;
	.size(100%);

	&__media {
		flex: 3;
		width: 100%;
		aspect-ratio: 1 / 1;
		padding: 1.25rem;
		overflow: hidden;

		&__item {
			.size(100%);
			background-size: contain;
			background-position: center;
			background-repeat: no-repeat;
		}
	}

	&__dots {
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: stretch;
		gap: .5rem;

		&__item {
			.size(.75rem);
			border: none;
			border-radius: .5rem;
			overflow: hidden;
			background-color: var(--page-color-text);
			opacity: .5;
			cursor: pointer;
			transition: opacity .2s linear, background-color .2s linear;

			&:hover,
			&__m-active {
				opacity: 1;
			}

			&__m-active {
				background-color: var(--page-color-accent);
				cursor: default;
			}
		}
	}

	&__title {
		flex: 1;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		text-align: center;
		opacity: .75;

		&__value {
			font-size: .8rem;
			font-weight: 400;
			padding: 0 .5rem;
		}
	}
}
