.page {
	position: relative;
	flex: 1;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: stretch;
	max-width: 1200px;
	margin: 1rem;
}
