@import 'src/style/use';

.box {
	.size(100%);

	&__m-padding {
		padding: 1rem 2rem;
	}

	&__m-background {
		.with-background();
	}

	&__m-border-top {
		.with-gradient-border-top();
	}

	&__m-bordder-bottom {
		.with-gradient-border-bottom();
	}
}
