@import 'src/style/use';

.status {
	.size(1.5rem);
	border-radius: .75rem;
	border: .25rem solid var(--page-color-lightest);

	&__m-status-idle {
		background-color: var(--page-color-status-idle);
	}

	&__m-status-waiting {
		background-color: var(--page-color-status-waiting);
	}

	&__m-status-success {
		background-color: var(--page-color-status-success);
	}

	&__m-status-error {
		background-color: var(--page-color-status-error);
	}
}
