.heading {
	text-transform: uppercase;
	padding-inline-start: 2rem;
	font-weight: 600;

	&__m-l-1 {
		font-size: 2.25rem;
	}

	&__m-l-2 {
		font-size: 2rem;
	}

	&__m-l-3 {
		font-size: 1.75rem;
	}

	&__m-l-4 {
		font-size: 1.5rem;
	}

	&__m-l-5 {
		font-size: 1.25rem;
	}

	&__m-l-6 {
		font-size: 1rem;
	}

	&__m-v-base {
		color: var(--page-color-text);
	}

	&__m-v-accent {
		color: var(--page-color-accent);
	}

	&__m-f-base {
		font-family: var(--page-font-family-base);
	}

	&__m-f-accent {
		font-family: var(--page-font-family-accent);
	}
}
