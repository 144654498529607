@import 'src/style/use';

.connect-account-button {
	position: relative;
	.size(auto, 7.125rem);
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	text-decoration: none;
	padding: 1.25rem 3rem;
	font-size: 1.75rem;
	gap: .5rem;
	border: 1px solid currentColor;
	transition: color .2s linear;
	cursor: pointer;

	&:hover {
		.connect-account-button {
			&__icon {
				transform: translateX(-10rem);
				opacity: 0;
			}

			&__label {
				transform: translateX(10rem);
				opacity: 0;
			}

			&__status {
				transform: scale(1) translate(-50%, -50%);
				opacity: 1;
			}
		}
	}

	&&__m-twitch {
		color: var(--page-color-twitch);

		&:hover {
			color: var(--page-color-text);

			&.connect-account-button__m-connected .connect-account-button {
				&__icon,
				&__label,
				&__status {
					color: var(--page-color-twitch);
				}
			}
		}
	}

	&&__m-google {
		color: var(--page-color-google);

		&:hover {
			color: var(--page-color-text);

			&.connect-account-button__m-connected .connect-account-button {
				&__icon,
				&__label,
				&__status {
					color: var(--page-color-google);
				}
			}
		}
	}

	&&__m-telegram {
		color: var(--page-color-telegram);

		&:hover {
			color: var(--page-color-text);

			&.connect-account-button__m-connected .connect-account-button {
				&__icon,
				&__label,
				&__status {
					color: var(--page-color-telegram);
				}
			}
		}
	}

	&&__m-connected {
		&:before {
			content: '';
			position: absolute;
			inset: 1rem;
			background-color: currentColor;
		}

		&:after {
			content: '✓';
			position: absolute;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			top: 0;
			right: 2rem;
			width: 2rem;
			height: 2rem;
			font-family: var(--page-font-family-mono);
			font-weight: 800;
			font-size: 1.25rem;
			line-height: 1.25rem;
			color: currentColor;
			background-color: var(--page-color-base);
			white-space: nowrap;
			border-radius: 0 0 1rem 1rem;
		}

		.connect-account-button {
			&__icon,
			&__label,
			&__status {
				color: var(--page-color-text);
			}
		}
	}

	&__m-disabled {
		pointer-events: none;
	}

	&__icon {
		position: relative;
		font-size: 3rem;
		opacity: 1;
		transform: translateX(0);
		transition: opacity .2s linear, transform .2s linear;
		z-index: 1;
	}

	&__label {
		position: relative;
		text-transform: uppercase;
		opacity: 1;
		transform: translateX(0);
		transition: opacity .2s linear, transform .2s linear;
		z-index: 1;
	}

	&__status {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: scale(2) translate(-25%, -25%);
		transform-origin: center center;
		opacity: 0;
		transition: opacity .2s linear, transform .2s linear;
		text-transform: uppercase;
		white-space: nowrap;
		z-index: 2;
	}
}
